(function($){
	$.fn.instagram = function(options) {
		var that = this,
		promises = [],
		settings = {
			hash: null,
			search: null,
			accessToken: null,
			hashtag: null,
			clientId: String,
			show: null,
			onLoad: null,
			onComplete: null,
			maxId: null,
			minId: null,
			userId: null
		};

		options && $.extend(settings, options);

		function composeRequestURL() {
			return "https://api.instagram.com/v1/tags/"+ settings.hashtag +"/media/recent?count=8&access_token=" + settings.accessToken;
		}

		// calback on load
		settings.onLoad != null && typeof settings.onLoad == "function" && settings.onLoad();

		$.ajax({
			type: "GET",
			dataType: "jsonp",
			cache: false,
			url: composeRequestURL(),
			success: function(res) {
				var limit = settings.show == null ? res.data.length : settings.show;

				if (typeof res.data !== "undefined" && res.data.length) {

					var box  = 0;
					var row  = 0;
					var html = '';


					$.each(res.data, function(index, value) {
						
						box = box + 1;
						row = row + 1;
						
						if (box == 1) {
							html += '<div class="item">';
						}

						if (row == 1) {
							html += '<div class="row"><div class="col-md-12 col-sm-12 col-xs-12">';
						}

						html += '<div class="col-md-3 col-sm-3 col-xs-6 reset-list">'+
							'<a href="' + value.images.standard_resolution.url +'" data-lightbox="instagram" title="'+ value.caption.text +'">' +
								'<img src="'+ value.images.standard_resolution.url +'" width="640" height="640" alt="'+ value.caption.text +'">' +
							'</a>' + 
						'</div>';

						if (row == 4 || res.data.length == index)
						{
							html += '</div></div>';
						}

						if (box == 4 || res.data.length == index)
						{
							html += '</div>';
						}

						if (row == 4)
						{
							row = 0;
						}

						if (box == 4)
						{
							box = 0;
						}

						promises.push(value);  
					});

					$.when.apply($, promises).always(function() {
		                $(that).html(html);
		            });					
				}

				// callback on complete
				settings.onComplete != null && typeof settings.onComplete == "function" && settings.onComplete(res.data);
			}
		});

		return this;
	};
})(jQuery);
